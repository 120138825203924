import React from "react";
import classNames from "classnames";

type Props = {
  className?: string;
  hasError?: boolean;
  label: React.ReactNode;
  htmlFor: string;
};

const FormElementLabel: React.FC<Props> = ({
  className,
  hasError,
  label,
  htmlFor,
}) => {
  const classes = classNames(className, { "text-error": hasError });
  return (
    <label htmlFor={htmlFor} className={classes}>
      <span className={classes}>{label}</span>
    </label>
  );
};

export default FormElementLabel;
