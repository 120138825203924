import React, { forwardRef } from "react";
import classNames from "classnames";

import { Themes } from "custom-types/Themes";

type Props = {
  className?: string;
  theme?: Themes.light;
  children: React.ReactNode;
};

const FormField = forwardRef<HTMLDivElement, Props>(
  ({ className, theme, ...others }, ref) => {
    const classes = classNames(
      "form__field form__field--js",
      { "form--light": theme === Themes.light },
      className,
    );

    return <div className={classes} ref={ref} {...others} />;
  },
);

export default FormField;
